import axios, { AxiosError } from "axios";
import { useRouter } from "vue-router";

type UNAUTHORIZED_RESPONSE = {
  detail: string
}

const INTERCEPTOR_DISABLED_ROUTES = [
  // login api
  import.meta.env.VITE_APP_API_URL + 'account/login',

  // google login
  import.meta.env.VITE_APP_API_URL + 'account/google/login/callback',

  // refresh token api
  import.meta.env.VITE_APP_API_URL + 'account/refresh-token'
]

export const useAxiosInterceptor = () => {
  const router = useRouter()

  const logoutUser = async () => {
    //Remove Token
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('full_name')
    localStorage.removeItem('selected_model')

    router.push('/auth/login');
  }

  const isRefreshingToken = () => {
    // lock token flag
    return localStorage.getItem('is_refreshing_token') === "true";
  }

  const acquireRefreshTokenLock = () => {
    // lock token flag
    localStorage.setItem('is_refreshing_token', "true");
  }

  const releaseRefreshTokenLock = () => {
    // lock token flag
    localStorage.removeItem('is_refreshing_token');
  }

  const requestInterceptor = axios.interceptors.request.use(function (config) {
    // check is request's endpoint in INTERCEPTOR_DISABLED_ROUTES or not
    if (config.url !== undefined && INTERCEPTOR_DISABLED_ROUTES.includes(config.url)) {
      return config
    }
    
    // Check is token defined in localstorage or not
    if(localStorage.getItem('token') !== null){
      // Bind Authorization header if it is not already set
      if(config.headers["Authorization"] === undefined) {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`
      }
    }
    return config;
  }, function (error) {
    // Do nothing
    return Promise.reject(error);
  });

  const responseInterceptor = axios.interceptors.response.use(
    function (response) {
      // No action required for status 200

      return response;
    }, async function (error) {
      // check is request's endpoint in INTERCEPTOR_DISABLED_ROUTES or not
      if (error.url !== undefined && INTERCEPTOR_DISABLED_ROUTES.includes(error.url)) {
        return Promise.reject(error);
      }

      // Handle 401 and specific 403
      if(
        (error as AxiosError).response !== undefined &&
        (error as AxiosError).response?.data !== undefined
      ) {
        const error_message = ((error as AxiosError).response?.data as UNAUTHORIZED_RESPONSE).detail
        if(
          (
            (error as AxiosError).response?.status === 401 ||
            (error as AxiosError).response?.status === 403
          ) &&
          (
            error_message === "Invalid token." ||
            error_message === "User inactive or deleted."
          )
        ){
          // Invalid token case
          logoutUser()
        }

        else if(
          (error as AxiosError).response?.status === 403 &&
          error_message === "Token expired."
        ){
          const originalRequest = error.config;

          if(isRefreshingToken() === true) {
            // wait token to be refreshed (pooling)
            while(isRefreshingToken() === true) {
              // sleep
              await new Promise(resolve => setTimeout(resolve, 100))
            }

            if(localStorage.getItem('token') !== null){
              // retry this request after token refreshed

              originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
              return axios(originalRequest);
            }
            else{
              // in case refresh token fail, return reject
              return Promise.reject(error);
            }
          }
          else{
            // Access token expired case
            acquireRefreshTokenLock()

            // call api to refresh token
            try {
              const refreshResponse = await axios.post(import.meta.env.VITE_APP_API_URL + 'account/refresh-token', {
                refresh_token: localStorage.getItem('refresh_token')
              });

              localStorage.setItem('token', refreshResponse.data.access_token);
              localStorage.setItem('refresh_token', refreshResponse.data.refresh_token);
              // Retry the original request with the new token
              originalRequest.headers['Authorization'] = `Bearer ${refreshResponse.data.access_token}`;
              return axios(originalRequest);
            } catch (refreshError) {
              // If refresh fails, logout the user
              logoutUser()
            }
            finally {
              releaseRefreshTokenLock()
            }
          }
        }
      }

      return Promise.reject(error);
    }
  )

  return { requestInterceptor, responseInterceptor }
}